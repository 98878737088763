import React from "react"
import { Container, Navbar, Nav } from "react-bootstrap"
import BoardIcon from "../assets/reliable.png"
import HandsIcon from "../assets/hands.png"
import PersonIcon from "../assets/person.png"
import HistoryImage from "../assets/history.jpg"
import PhoneIcon from "../assets/phone.png"
import EmailIcon from "../assets/email.png"
import Logo from "../assets/logo.png"
import style from "../scss/pages/home.module.scss"

const Home = () => {
  return (
    <div className={style.homePage}>
      <section className={style.headerSection}>
        <header>
          <Navbar collapseOnSelect expand="lg">
            <Container>
              <Navbar.Brand href="/">
                <img className={style.logo} src={Logo} alt="logo" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ms-auto">
                  <Nav.Link href="#" onClick={() =>  window.scrollTo(0, 0)}>About Us</Nav.Link>
                  <Nav.Link href="#objective">Objective</Nav.Link>
                  <Nav.Link href="#mission">Mission</Nav.Link>
                  <Nav.Link href="#history">History</Nav.Link>
                  <Nav.Link href="#contactus">Contact Us</Nav.Link>
                  {/* <Nav.Link href="#">Login(Coming Soon)</Nav.Link> */}
                  <Nav.Link href="https://portal.oursg.co" target="_blank">Login</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <div className={`${style.headerContent} d-flex align-items-center`}>
            <div className={style.content}>
              <h2>OUR SG is the brand for One United Resources Singapore.</h2>
              <p>
                The logo carries the meaning of support symbolized by the hand
                done with care symbolized by the heart in red to represent
                energy, always in motion and human.
              </p>
              <p>
                The red circle symbolizes continuity embracing all business
                offered by OUR SG
              </p>
            </div>
          </div>
        </header>
      </section>
      <section className={style.sloganSection}>
        <Container>
          <h2 className="mb-0 text-center text-white">
            OUR SG Corporate slogan is Service Per Excellence
          </h2>
        </Container>
      </section>
      <Container>
        <section className={style.missionSection} id="mission">
          <h2 className="text-center">What is OUR SG's Mission?</h2>
          <div className={`${style.missionItems} d-flex gap-4`}>
            <div className={`${style.missionItem} text-center`}>
              <img src={BoardIcon} alt="icon1" />
              <p className="mb-0 mt-4">
                To be the reliable source of biodata supply to EAs of Migrant
                Worker from multi countries and multi suppliers that are
                selected
              </p>
            </div>
            <div className={`${style.missionItem} text-center`}>
              <img src={HandsIcon} alt="icon1" />
              <p className="mb-0 mt-4">
                To be the Singapore based organisation as the bridge between our
                Suppliers and EAS
              </p>
            </div>
            <div className={`${style.missionItem} text-center`}>
              <img src={PersonIcon} alt="icon1" />
              <p className="mb-0 mt-4">
                To be the platform supporting our Migrant Workers while they are
                working in Singapore and provide growth development for their
                future
              </p>
            </div>
          </div>
        </section>
      </Container>
      <section
        className={`${style.objectiveSection} d-flex align-items-center`}
        id="objective"
      >
        <div className={style.content}>
          <h2>OUR SG's Objectives</h2>
          <ul>
            <li>
              To provide good source and supply of biodata by working with
              reliable and known suppliers and EAs
            </li>
            <li>
              To work with suppliers who will train their MDWs with 3 core
              fundamentals (Core skills, Core values and Core fitness)
            </li>
            <li>
              To help enhance the working relationship between Employers and
              MDWs
            </li>
            <li>
              To give MDWs access to development training while working in
              Singapore to prepare them for their return
            </li>
            <li>
              To develop Corporate Service Responsibility (CSR) programs to
              improve the industry condition
            </li>
            <li>
              To develop other complementary line of business for the well being
              of the MDW
            </li>
          </ul>
        </div>
      </section>
      <Container>
        <section
          className={`${style.historySection} d-flex align-items-center`}
          id="history"
        >
          <div className={style.content}>
            <h2 className="mb-3">OUR History</h2>
            <p>
              OUR came into existence about 20 years ago to standardize the
              publicity of biodata distribution and this has been warmly
              received by the EAS even till today
            </p>
            <p>
              OUR has been engaging various source countries to explore
              developing training centres in Indonesia, Cambodia and Myanmar
              with the involvement of EAS participating at source countries
            </p>
            <p>
              OUR has over the years been able to develop strong direct
              relationships and trust with reliable suppliers in various
              countries hence we will be able to provide good helpers
            </p>
            <p>
              OUR believe is to get suppliers to have their helpers trained in
              basic core fundamentals (core skills, core values and core
              fitness)
            </p>
          </div>
          <div className={style.imgWrap}>
            <img src={HistoryImage} alt="history" />
          </div>
        </section>
      </Container>
      <section className={style.contactSection} id="contactus">
        <Container>
          <h2 className="mb-3 text-center text-white">Contact Us</h2>
          <div
            className={`${style.info} d-flex justify-content-center gap-5 mt-5`}
          >
            <div className="d-flex">
              <img src={PhoneIcon} alt="phone" />
              <div>
                <p className="mb-0">+65 9661 6361 (Vivian)</p>
                <p className="mb-0">+65 8781 6058 (Edwin)</p>
              </div>
            </div>
            <div className="d-flex">
              <img src={EmailIcon} alt="email" />
              <div>
                <p className="mb-0">vivian.oursgp@gmail.com</p>
                <p className="mb-0">oursgp@gmail.com</p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  )
}

export default Home
